
































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { api } from "@/api";
import { appName } from "@/env";
import { readLoginError } from "@/store/main/getters";
import {
  dispatchCheckLoggedIn,
  dispatchSelectPlan,
  dispatchGetServiceAddress,
  dispatchGetBillingAddress,
} from "@/store/main/actions";
import { readServiceAddress, readBillingAddress } from "@/store/main/getters";
import _ from "lodash";

@Component({
  components: {},
})
export default class TC extends Vue {}
