













































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { api } from "@/api";
import { appName, merchantURL } from "@/env";
import { readLoginError } from "@/store/main/getters";
import VuetifyGoogleAutocomplete from "./VuetifyGoogleAutocomplete.vue";
import {
  dispatchCheckLoggedIn,
  dispatchSelectPlan,
  dispatchGetServiceAddress,
  dispatchGetBillingAddress,
  dispatchGetServices,
  dispatchGetPromos,
  dispatchPostFreeBill,
  dispatchUpgradePlan,
} from "@/store/main/actions";
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import { readServiceAddress, readBillingAddress } from "@/store/main/getters";
import _ from "lodash";
import Progress from "./Progress.vue";
import { formatAddress } from "../utils";
import Loading from "./Loading.vue";
import TC from "./TC.vue";
import { readIsLoggedIn, readUserProfile } from "@/store/main/getters";

@Component({
  components: {
    VuetifyGoogleAutocomplete,
    Header,
    Footer,
    Progress,
    Loading,
    TC,
  },
  props: {
    upgrade: {
      type: Boolean,
      default: false,
    },
    ptype: {
      type: String,
      default: "GC",
    },
    onClose: {
      type: Function,
    },
  },
})
export default class Plans extends Vue {
  public valid = true;
  public validated = false;
  public dialogMini = false;
  public selected = 1;
  public router = 1;
  public showPlan = true;
  public showPmethod = true;
  public showOrder = true;
  public showHeaderFooter = true;
  public showUpgradeButton = false;
  public showUpgradeComplete = false;
  public headers = {
    plan: "",
    price: "",
    recommended_users: "",
    tech_download: "",
    tech_upload: "",
    vr: "",
    fhd: "",
    fourK: "",
    experience: "",
  };
  public routers = [
    {
      id: 1,
      router: 1,
      name: "Standard Router",
      image: "/img/plans/router@3x.png",
      model: "Huawei HS8145v5",
      price: "100",
      currency: "PHP",
      description: `
          <div class="RouterDesc">
          Horizon Fiber Home Router Features:<br/>
          • An advanced router device that gives the right connectivity
          solution for home connectivity. <br/>
          • A high-performing device perfect for
          your home connection with its forwarding capability that ensures
          quality service of voice, data and high-definition videos. <br/>
          • Has a
          green energy saving and intelligent capacity right for your Internet
          connectivity at home.<br/>
          </div>
      `,
    },
  ];
  public plans = [
    {
      plan: 1,
      name: "FiberGo",
      speed: "25",
      speed_unit: "mbps",
      price: 1857,
      cc_plan_price: 1688,
      router_price: 2500,
      installation_price: 2500,
      cc_installation_price: 0,
      contract_length: 24,
      router_payment: "Installment",
      currency: "PHP",
      description: "50% min speed",
      description2: "80% service reliability",
      is_cc: true,
      is_gc: true,
      recommended_users: "Family Size: <b>2</b>",
      tech_download: "1GB @min speeds: <b>10 mins</b>",
      tech_upload: "1TB Cloud Backups: <b>7.4 days</b>",
      vr: "VR: <b>N/A</b>",
      fhd: "FHD Streams: <b>2</b>",
      fourK: "4K Streams: <b>0</b>",
      experience:
        '<div class="ServiceDesc">\n • 50% min speed<br/> • 80% service reliability<br/> • 24 month lock-in period<br/> • Installation payments for the Wi-fi router. <br/>• Installation fee of 2500 PHP waived for credit card payments </div>',
    },
    {
      plan: 2,
      name: "FiberMove",
      speed: "50",
      speed_unit: "mbps",
      price: 2737,
      cc_plan_price: 2488,
      router_price: 2500,
      installation_price: 2500,
      cc_installation_price: 0,
      contract_length: 24,
      router_payment: "Installment",
      currency: "PHP",
      description: "50% min speed",
      description2: "90% service reliability",
      is_cc: true,
      is_gc: true,
      recommended_users: "Family Size: <b>2-3</b>",
      tech_download: "1GB @min speeds: <b>6 mins</b>",
      tech_upload: "1TB Cloud Backups: <b>3.7 days</b>",
      vr: "VR: <b>240p</b>",
      fhd: "FHD Streams: <b>5</b>",
      fourK: "4K Streams: <b>1</b>",
      experience:
        '<div class="ServiceDesc">\n • 50% min speed<br/> • 90% service reliability<br/> • 24 month lock-in period<br/> • Installation payments for the Wi-fi router. <br/>• Installation fee of 2500 PHP waived for credit card payments.</div>',
    },
    {
      plan: 3,
      name: "FiberBeyond",
      speed: "100",
      speed_unit: "mbps",
      price: 3287,
      cc_plan_price: 2988,
      router_price: 2500,
      installation_price: 2500,
      cc_installation_price: 0,
      contract_length: 24,
      router_payment: "Installment",
      currency: "PHP",
      description: "50% min speed",
      description2: "90% service reliability",
      is_cc: true,
      is_gc: true,
      recommended_users: "Family Size: <b>4-5</b>",
      tech_download: "1GB @min speeds: <b>3 mins</b>",
      tech_upload: "1TB Cloud Backups: <b>1.9 days</b>",
      vr: "VR: <b>240p</b>",
      fhd: "FHD Streams: <b>10</b>",
      fourK: "4K Streams: <b>2</b>",
      experience:
        '<div class="ServiceDesc">\n • 50% min speed<br/> • 90% service reliability<br/> • 24 month lock-in period<br/> • Installation payments for the Wi-fi router. <br/>• Installation fee of 2500 PHP waived for credit card payments.</div>',
    },
    {
      plan: 4,
      name: "FiberPremium",
      speed: "100",
      speed_unit: "mbps",
      price: 4388,
      cc_plan_price: 4388,
      router_price: 2500,
      installation_price: 2500,
      cc_installation_price: 0,
      contract_length: 24,
      router_payment: "One Time",
      currency: "PHP",
      description: "80% min speed",
      description2: "90% service reliability",
      is_cc: true,
      is_gc: false,
      recommended_users: "Family Size: <b>5+</b>",
      tech_download: "1GB @min speeds: <b>2 mins</b>",
      tech_upload: "1TB Cloud Backups: <b>1.1 days</b>",
      vr: "VR: <b>Standard</b>",
      fhd: "FHD Streams: <b>16</b>",
      fourK: "4K Streams: <b>3</b>",
      experience:
        '<div class="ServiceDesc">\n • 80% min speed<br/> • 90% service reliability<br/> • 12 month lock-in period<br/> • Upfront payments for the Wi-fi router. <br/>• Installation fee of 2500 PHP waived for credit card payments.</div>',
    },
  ];
  public allPlans = [];
  public appName = appName;
  public merchantURL = merchantURL;
  public unit = "";
  public address = "";
  public promoCode = "";
  public place = "";
  public placeId = "";
  public addressInfo = "";
  public addressData = "";
  public agree = false;
  public paymentrequest = "";
  public serviceAddress = "";
  public billingAddress = "";
  public pmethod = "CC";
  public routerpmethod = "One Time";
  public ip = "";
  public showLoading = false;
  public promos = [];
  public promo: any = null;
  public price: any = null;
  public addressHint = "";
  public planColors = {
    1: "#357c0d",
    2: "#357c0d",
    3: "#2885c6",
    4: "#2885c6",
  };
  $refs!: Vue["$refs"] & {
    map: typeof VuetifyGoogleAutocomplete;
    paymentrequest: HTMLFormElement;
    payment: HTMLFormElement;
  };

  public async mounted() {
    if (this.$props.upgrade) {
      this.showOrder = false;
      this.showHeaderFooter = false;
      this.showUpgradeButton = true;
    }
    await dispatchGetServices(this.$store, (response) => {
      this.routers = response.routers;
      this.plans = response.services;
      this.allPlans = response.services;
      this.ip = response.ip;
      if (this.$props.upgrade) {
        const service_id = _.get(this.userProfile, "service_id", 1);

        this.plans = this.plans.filter(
          (plan) => _.get(plan, "plan", 0) > service_id
        );
        this.allPlans = this.allPlans.filter(
          (plan) => _.get(plan, "plan", 0) > service_id
        );
        this.pmethod = this.$props.ptype;
        this.showPmethod = false;
      }
    });

    await dispatchGetServiceAddress(this.$store, (response) => {
      this.serviceAddress = formatAddress(response);
    });
    await dispatchGetBillingAddress(this.$store, (response) => {
      this.billingAddress = response.full_address;
      this.address = this.billingAddress;
      this.$refs.map.update(this.address);
    });
    this.updatePrice();
  }
  get userProfile() {
    return readUserProfile(this.$store);
  }
  public async getPromo() {
    const callback = (data) => {
      this.promos = _.get(data, "data.promos", []);
      this.updatePrice();
    };
    const payload = {
      code: this.promoCode,
      callback: callback,
    };
    await dispatchGetPromos(this.$store, payload);
  }

  public getPlanColor(plan) {
    return this.planColors[plan];
  }
  public showAgreeTC(toggle) {
    this.dialogMini = toggle;
    if (!toggle) {
      this.agree = true;
    }
    setTimeout(() => {
      this.$refs.map.update(this.address);
    }, 500);
  }
  public get loginError() {
    return readLoginError(this.$store);
  }

  public validate() {
    if (this.address) {
      const addresses = this.address.split(", ");
      if (addresses.length < 3) {
        this.addressHint =
          "Please select your address from the address selection menu";
      } else {
        this.addressHint = "";
      }
    }
    return (
      !this.addressHint && !!this.selected && !!this.address && !!this.agree
    );
  }

  public pmethodChanged(pm) {
    let is_cc = false;
    let is_gc = false;
    if (pm === "GC") {
      is_cc = false;
      is_gc = true;
      if (this.selected > 3) {
        this.selected = 3;
      }
    } else {
      is_cc = true;
      is_gc = false;
    }
    this.plans = this.allPlans.filter(
      (plan) =>
        _.get(plan, "is_cc", null) === is_cc ||
        _.get(plan, "is_gc", null) === is_gc
    );
    this.updatePrice();
  }
  public selectRouter(router) {
    this.router = router;
  }

  public getRouterPrice(router) {
    const plan = _.first(
      this.plans.filter((item) => item.plan === this.selected)
    );
    if (plan.router_price) {
      return plan.router_price.toLocaleString();
    } else {
      return plan.router_price;
    }
  }
  public selectPlan(plan) {
    this.selected = plan;
    const planInfo = _.first(
      this.plans.filter((item) => item.plan === this.selected)
    );
    if (planInfo.router_payment === "One Time") {
      this.routerpmethod = "One Time";
    }
    this.updatePrice();
  }

  public updatePrice() {
    this.getPrice();
  }

  public selectedPlanHasNoInstallment() {
    const planInfo = _.first(
      this.plans.filter((item) => item.plan === this.selected)
    );
    if (planInfo.router_payment === "One Time") {
      this.routerpmethod = "One Time";
      return true;
    }
    return false;
  }

  public getPrice() {
    if (this.plans && this.plans.length) {
      const plan = _.first(
        this.plans.filter((item) => item.plan === this.selected)
      );
      const router = _.first(
        this.routers.filter((item) => item.router === this.router)
      );

      const promo = _.first(
        this.promos.filter((item) => _.get(item, "service_id", 0) === plan.id)
      );

      const referral_cid = _.get(this.userProfile, "referral_cid", "");

      const price = {
        original: _.get(plan, "price", 0),
        planPrice: _.get(plan, "price", 0),
        originalRouterPrice: _.get(router, "price", 0),
        routerPrice: _.get(router, "price", 0),
        originalInstPrice: _.get(plan, "installation_price", 0),
        instPrice: _.get(plan, "installation_price", 0),
        ccPlanPrice: _.get(plan, "cc_plan_price", null),
        ccInstPrice: _.get(plan, "cc_installation_price", 0),
        contractLength: _.get(plan, "contract_length", 12),
        routerPayment: _.get(plan, "router_payment", ""),
        planPromoDiscount: 0,
        ccPromoDiscount: 0,
        instPromoDiscount: 0,
        ccInstPromoDiscount: 0,
        routerPromoDiscount: 0,
        promoDiscount1: 0,
        promoDiscount2: 0,
        promoDiscount: 0,
        referDiscount: referral_cid ? 100 : 0,
        total: 0,
      };

      if (promo) {
        price.planPromoDiscount =
          price.planPrice - _.get(promo, "promo_price", 0);
        price.ccPromoDiscount =
          price.ccPlanPrice - _.get(promo, "promo_cc_price", 0);
        price.instPromoDiscount =
          price.instPrice - _.get(promo, "promo_installation_price", 0);
        price.ccInstPromoDiscount = 0;
        price.routerPromoDiscount =
          price.routerPrice - _.get(promo, "promo_router_price", 0);
        price.contractLength = _.get(promo, "promo_contract_length", 0);
      }

      if (this.pmethod === "CC") {
        price["ccPlanDiscount"] = price.original - price.ccPlanPrice;
        price["ccInstDiscount"] = price.originalInstPrice - price.ccInstPrice;
        price["promoDiscount1"] =
          price.ccPromoDiscount + price.ccInstPromoDiscount;
        price["total"] =
          price.ccPlanPrice + price.ccInstPrice - price.referDiscount;
      } else {
        price["planDiscount"] = price.original - price.planPrice;
        price["instDiscount"] = price.originalInstPrice - price.instPrice;
        price["promoDiscount1"] =
          price.planPromoDiscount + price.instPromoDiscount;
        price["total"] =
          price.planPrice + price.instPrice - price.referDiscount;
        price["ccPlanPrice"] = null;
        price["ccInstPrice"] = null;
      }
      price["routerDiscount"] = price.originalRouterPrice - price.routerPrice;
      if (this.routerpmethod === "One Time") {
        price["total"] += price.routerPrice;
        price["promoDiscount2"] = price.routerPromoDiscount;
      } else {
        price["routerPrice"] = Math.round(
          price.routerPrice / price.contractLength
        );
        price["total"] += price.routerPrice;
        price["promoDiscount2"] = Math.round(
          price.routerPromoDiscount / price.contractLength
        );
      }
      price.promoDiscount = price.promoDiscount1 + price.promoDiscount2;
      price["total"] -= price.promoDiscount;
      if (price["total"] < 0) {
        price["total"] = 0;
      }
      price["currency"] = plan.currency;
      this.price = price;
      this.promo = promo;
      return price;
    }
    this.price = null;
    return null;
  }

  public getTotalPrice() {
    if (this.routers && this.routers.length) {
      const router = _.first(
        this.routers.filter((item) => item.router === this.router)
      );
      const price = _.get(router, "price", 0);
      const price2 = _.get(this, "price", 0);

      return parseInt(price) + parseInt(price2);
    }
    return 0;
  }

  public getAddressData(addressData, placeResultData, id) {
    this.addressData = addressData;
    this.place = placeResultData;
    this.placeId = id;
    this.addressInfo = `Address Detail: ${JSON.stringify(
      addressData
    )} ${JSON.stringify(placeResultData)} ${id}`;
  }
  public updateAddress(newAddress) {
    this.address = newAddress;
    this.validate();
  }
  public async submit() {
    this.showLoading = true;
    var response = dispatchSelectPlan(this.$store, {
      address: this.address,
      addressData: this.addressData
        ? `${JSON.stringify(this.addressData)}`
        : "",
      placeData: this.place ? `${JSON.stringify(this.place)}` : "",
      placeId: this.placeId ? `${JSON.stringify(this.placeId)}` : "",
      ip: this.ip,
      serviceId: this.selected,
      routerId: this.router,
      pmethod: this.pmethod,
      routerpmethod: this.routerpmethod,
      rebill: 0,
      promoCode: _.get(this.promo, "code", ""),
    }).then((response) => {
      const data = _.get(response, "data.data.payload", "");
      const amount = parseFloat(_.get(response, "data.data.amount", 1));
      const request_id = _.get(response, "data.data.request_id", 1);
      if (amount == 0 && request_id) {
        dispatchPostFreeBill(this.$store, {
          request_id: request_id,
        }).then((response) => {
          const redir = _.get(response, "data.data.redirect", "");
          location.href = redir;
        });
      } else {
        // document.getElementById("paymentrequest").value = data;
        // document.getElementById("payment").submit();
        this.$refs.paymentrequest.value = data;
        this.$refs.payment.submit();
      }
    });
  }

  public async upgradePlan() {
    this.showLoading = true;
    var response = dispatchUpgradePlan(this.$store, {
      serviceId: `${this.selected}`,
    })
      .then((response) => {
        this.showLoading = false;
        this.$props.onClose();
      })
      .catch((error) => {
        this.showLoading = false;
        alert("There was an error upgrading the plan.");
      });
  }
}
